import { defineStore } from "pinia";

export const useIntegrationStore = defineStore({
  id: "integration-store",
  state: () => {
    return {
      integrations: {},
      zoomIntegrations: {},
      niftyImages: {},
      niftyImagesIntegrationID: "",
    };
  },
  actions: {
    async fetchIntegrations() {
      const response = await call("/integration/get", "POST");
      if (response?.success) {
        this.integrations = response.success;
      }
      return this.integrations;
    },
    async testIntegration(id) {
      const payload = {
        integrationID: id,
      };
      const response = await call("/test/integration", "POST", payload, true);
      const message = response?.error?.response?.data?.message ?? "";
      if (response?.success) {
        this.integrations[id].connected = true;
        return { connected: true };
      }
      this.integrations[id].connected = false;
      return { connected: false, message };
    },
    async refreshIntegration(integrationName, id) {
      const payload = {
        integrationID: id,
      };
      let formData = new FormData();
      formData.append("integrationID", id);
      const response = await call(
        `/${integrationName.toLowerCase()}/getlists`,
        "POST",
        formData
      );
      if (response) {
        const { success } = response;
      }
      return response;
    },
    async usedIntegration(id) {
      const payload = {
        integrationID: id,
      };
      const response = await call("/integration/used", "POST", payload);
      if (response) {
        const { success } = response;
      }
      return response;
    },
    async disconnectIntegration(id) {
      const payload = {
        integrationID: id,
      };
      const response = await call("/integration/delete", "POST", payload);
      let deleteResponse = false;
      if (response) {
        const { success } = response;
        deleteResponse = Object.fromEntries(
          Object.entries(this.integrations).filter(
            (el) => el[1].integrationid !== id
          )
        );
        this.integrations = deleteResponse;
        this.fetchIntegrations();
      }
      return deleteResponse;
    },
    async checkIntegration(integrationName, payload) {
      return await call(`/${integrationName}/check`, "POST", payload,integrationName=="salesforce"&&"sales");
    },
    async addIntegration(payload) {
      let formData = new FormData();
      formData.append("blocks", JSON.stringify(payload));
      const response = await call(`/integration/create`, "POST", formData);
      return response;
    },
    async updateIntegration(integrationID, payload) {
      let formData = new FormData();
      formData.append("integrationID", integrationID);
      formData.append("blocks", JSON.stringify(payload));
      const response = await call(`/integration/update`, "POST", formData);
      if (response) {
        const { success } = response;
        this.integrations[integrationID] = success;
      }
      return response;
    },
    // REFRESH INTEGRATION
    async getIntegrationList(
      intType,
      integrationID,
      refresh = false ? false : true
    ) {
      let type = intType.toLowerCase();
      const payload = {
        integrationID: integrationID,
        refresh: refresh,
      };
      const response = await call(`/${type}/getlists`, "POST", payload, true);
      if (response?.success) {
        // let setIntegration = this.createSetIntegration(
        //   response.success,
        //   integrationID
        // );
        return response;
      } else if (response.error?.response?.data?.message) {
        return { error: response.error.response.data.message };
      } else if (response.error?.response?.status === 429) {
        return {
          error:
            "Rate limited by the integration provider, please try again later.",
        };
      }
    },
    // ADD ADDITIONAL DATA TO AN INTEGRATION
    async addIntegrationData(integrationID, model) {
      let formData = new FormData();
      formData.append("integrationID", integrationID);
      formData.append("type", "encrypt");
      formData.append("blocks", model);
      const response = await call(`/integration/adddata`, "POST", formData);
      if (response) {
        if (!this.integrations[integrationID]) {
          return response;
        }
        Object.keys(model).map((sKey) => {
          this.integrations[integrationID][sKey] = model[sKey];
        });
        return response;
      }
    },
    async addSingleField(integrationName, id, listID, name) {
      const payload = {
        integrationID: id,
        listID: listID,
        name: name,
      };
      const response = await call(
        `/${integrationName}/addfield`,
        "POST",
        payload,
        true
      );
      if (!response?.error) {
        const { success } = response;
      } else if (response.error?.response?.data?.message) {
        return { error: response.error.response.data.message };
      }
      return response;
    },
    async createBulkTags(payload) {
      const response = await call(`/tags`, "POST", payload, true);
      if (response) {
        return response;
      }
      return response;
    },
    async addAutoField(integrationName, id, listID) {
      let formData = new FormData();
      formData.append("integrationID", id);
      formData.append("listID", listID);
      // const payload = {
      //   integrationID: id,
      //   listID: listID,
      // };
      const response = await call(
        `/${integrationName}/addautofield`,
        "POST",
        formData
      );
      if (response) {
        const { success } = response;
      }
      return response;
    },
    async addAudience(integrationID, adAccountId, name) {
      let formData = new FormData();
      formData.append("integrationID", integrationID);
      formData.append("adAccountId", adAccountId);
      formData.append("name", name);
      const response = await call(
        "/fbaudience/audience/create",
        "Post",
        formData
      );
      if (response) {
        const { success } = response;
      }
      return response;
    },
    async addTag(type, id, listID, name) {
      const payload = {
        integrationID: id,
        listID: listID,
        name: name,
      };
      let formData = new FormData();
      formData.append("integrationID", id);
      formData.append("listID", listID);
      formData.append("name", name.toLowerCase());
      const response = await call(
        `/${type.toLowerCase()}/addtag`,
        "POST",
        formData,
        true
      );
      if (response?.error?.response?.data?.message) {
        return { error: response.error.response.data.message };
      }
      return response;
    },
    fetchNiftyImages() {
      if (Object.keys(this.integrationsList).length > 0) {
        Object.values(this.integrationsList).map((item, idx) => {
          if (item.type === "NIFTYIMAGE") {
            this.niftyImages = item.images;
            this.niftyImagesIntegrationID = item.integrationid;
          }
        });
      }
      return this.niftyImages;
    },
    async deleteNiftyImages(url, name) {
      const payload = {
        integrationID: this.niftyImagesIntegrationID,
        url: url,
      };
      const response = await call("/niftyimage/delete", "POST", payload);
      if (response) {
        const filtered = Object.fromEntries(
          Object.entries(this.niftyImages).filter((el) => el[0] !== name)
        );
        this.niftyImages = filtered;
      }
      return response;
    },
    async testSmtp(val) {
      const payload = val;
      val["encrypt"] = true;
      val["method"] = "get";
      val["format"] = "standard";
      val["type"] = "SMTP";
      await call("/test/smtp", "POST", payload);
    },
    setIntegration(sourceObj, ...properties) {
      properties.forEach((property) => {
        // this.integrations[integrationID][property] = sourceObj[property];
      });
    },
    getZoomIntegrations() {
      if (Object.keys(this.integrationsList).length > 0) {
        Object.values(this.integrationsList).map((item, idx) => {
          if (item.type === "ZOOM") {
            this.zoomIntegrations[item.integrationid] = item;
          }
        });
      }
      return this.zoomIntegrations;
    },
    getMoreThenOne() {
      let Integrations = {};
      if (Object.keys(this.integrationsList).length > 0) {
        Object.values(this.integrationsList).map((item, idx) => {
          if (item.type === "ZOOM") {
            Integrations[item.integrationid] = item;
          }
        });
      }
      return Integrations;
    },
    async fetchWorksheets(integrationID, spreadsheet, drive = null) {
      let url = `/sheets/${integrationID}/${spreadsheet}`;
      if (drive) url += `?drive=${drive}`;
      return await call(url, "GET");
    },
    async fetchSheetsHeaders(
      integrationID,
      spreadsheet,
      worksheet,
      drive = null
    ) {
      let url = `/sheets/${integrationID}/${spreadsheet}/${worksheet}`;
      if (drive) url += `?drive=${drive}`;
      return await call(url, "GET");
    },
    supportsTags(integrationType) {
      const capabilities =
        INTEGRATION_ACTION_DETAILS[integrationType.toLowerCase()] ?? {};
      return !!capabilities.addTag;
    },
    getTagFor(integrationType, action = "addSubscriber") {
      const capabilities =
        INTEGRATION_ACTION_DETAILS[integrationType.toLowerCase()] ?? {};
      const tagKey = capabilities[action]?.Tag ?? capabilities.addTag?.Tag;
      return (val) => val?.[tagKey];
    },
    setTagFor(integrationType, action = "addSubscriber") {
      const capabilities =
        INTEGRATION_ACTION_DETAILS[integrationType.toLowerCase()] ?? {};
      const tagKey = capabilities[action]?.Tag ?? capabilities.addTag?.Tag;
      return (data, tag) => {
        if (typeof data !== "object" || !tag) return;
        data[tagKey] = tag;
      };
    },
  },
  getters: {
    integrationsList: (state) => state.integrations,
    niftyImagesList: (state) => state.niftyImages,
    getIntegration: (state) => (type) => {
      const integrationType = type.toUpperCase();
      return (
        Object.entries(state.integrations)?.filter(
          ([k, v]) => v?.type === integrationType
        )?.[0]?.[1] ?? {}
      );
    },
    trackingPixelIntegrations: (state) => {
      return TRACKING_PIXEL_INTEGRATIONS.flatMap((integrationType) =>
        Object.entries(state.integrations)
          ?.filter(
            ([integrationID, integration]) =>
              integration?.type === integrationType
          )
          ?.map(([integrationID, integration]) => integrationID)
      );
    },
    autoresponderIntegrations: (state) => {
      const filteredIntegrations = Object.values(state.integrations)
        .filter((el) => AUTORESPONDER_TYPE.includes(el.type))
        .reduce((obj, el) => {
          obj[el.integrationid] = el;
          return obj;
        }, {});

      return filteredIntegrations;
    },
  },
});

export const INTEGRATION_NAMES = {
  AEVENT: "AStream",
  AMAZONSES: "Amazon SES",
  CAMPAIGNMONITOR: "Campaign Monitor",
  CAMPAIGNREFINERY: "Campaign Refinery",
  CLICKBANK: "ClickBank",
  CONSTANTCONTACT: "Constant Contact",
  DOMAIN: "Custom Domain",
  DROPCOWBOY: "Drop Cowboy",
  FBAUDIENCE: "FB Custom Audience",
  HUBSPOT: "HubSpot",
  GETRESPONSE: "GetResponse",
  GOHIGHLEVEL: "GoHighLevel",
  GOOGLECALENDAR: "Google Calendar",
  GOOGLESHEETS: "Google Sheets",
  GOTOWEBINAR: "GoToWebinar",
  ICONTACT: "iContact",
  INFUSIONSOFT: "Keap",
  MAILGUN: "MailGun",
  MAILJET: "MailJet",
  MANYCHAT: "ManyChat Facebook",
  MANYCHATWHATSAPP: "ManyChat WhatsApp",
  ROEZAN: "Roezan",
  POSTMARK: "PostMark",
  SALESMESSAGE: "Sales Message",
  SENDGRID: "SendGrid",
  SENDINBLUE: "Brevo",
  SMARTENGAGE: "SmartEngage",
  SPARKPOST: "SparkPost",
  SLICKTEXT: "SlickText",
  VSL: "AConversion",
  WATI: "Wati",
  WEBINAR: "GoToWebinar",
  YOUTUBE: "YouTube",
  ZAPIER: "Zapier",
  PABBLY: "Pabbly",
  ZOOM: "Zoom",
  ZOOMAUTOCHAT: "Zoom Autochat",
  BUSINESSGLU: "Business Glu",
  GMAIL: "Gmail",
  CALENDLY: "Calendly",
  SALESFORCE: "Salesforce",
  METACAPI: "FB Conversion",
  SIMPLETEXTING: "Simple Texting",
  SUPERCHAT: "Super Chat",
  TIKTOKBUSINESSAPI: "TikTok for Business",
};

export const INTEGRATION_ACTION_DETAILS = {
  onesignal: {
    notification: { "Template ID": "template-id" },
  },
  manychat: {
    addTag: { Tag: "tagName" },
    removeTag: { Tag: "tagName" },
    sendText: { Text: "text" },
  },
  twilio: {
    textuser: { SMS: "sms-text" },
  },
  sendlane: {
    addTag: { Tag: "tagName" },
    removeTag: { Tag: "tagName" },
    moveList: {
      From: "fromListName",
      To: "toListName",
    },
    addSubscriber: { Tag: "tagID" },
  },
  mailchimp: {
    addTag: { Tag: "tagName" },
    addToWorkflow: { Workflow: "workflowName" },
    removeFromWorkflow: { Workflow: "workflowName" },
    moveList: {
      From: "fromListName",
      To: "toListName",
    },
  },
  infusionsoft: {
    addTag: { Tag: "tagID" },
    removeTag: { Tag: "tagID" },
    subscribeToCampaign: { Campaign: "campaignName" },
    removeFromCampaign: { Campaign: "campaignName" },
  },
  getresponse: {
    addTag: {
      Tag: "tag-id",
    },
    moveList: {
      From: "from-list",
      To: "to-list",
    },
    addSubscriber: { Tag: "tagID" },
  },
  drip: {
    addTag: { Tag: "tag-name" },
    removeTag: { Tag: "tag-name" },
    addToCampaign: { Campaign: "campaignName" },
    pauseCampaign: { Campaign: "campaignName" },
    startWorkflow: { Workflow: "workflowName" },
    removeFromWorkflow: { Workflow: "workflowName" },
  },
  aweber: {
    moveList: {
      From: "from-list",
      To: "to-list",
    },
    addTag: { Tag: "tag-name" },
  },
  slybroadcast: {
    vmmsg: {
      From: "from",
      Audio: "filename",
    },
  },
  webinar: {
    changesubject: { Subject: "subject" },
    livechat: { Name: "from", Message: "message" },
    encore: { Timeline: "timelineName" },
    cta: { Headline: "headline" },
  },
  obvio: {
    changesubject: { Subject: "subject" },
    livechat: { Name: "from", Message: "message" },
    encore: { Timeline: "timelineName" },
    cta: { Headline: "headline" },
  },
  activecampaign: {
    addTag: {
      Tag: "tagID",
    },
    removeTag: {
      Tag: "tagName",
    },
    addToAutomation: {
      Automation: "automationName",
    },
    removeFromAutomation: {
      Automation: "automationName",
    },
    moveList: {
      From: "listName",
      To: "toListName",
    },
    addSubscriber: { Tag: "registration" },
  },
  pushcrew: {
    notification: {
      "": "title",
    },
    addToSegment: {
      Segment: "segmentName",
    },
    removeFromSegment: {
      Segment: "segmentName",
    },
  },
  convertkit: {
    addTag: {
      Tag: "tagID",
    },
    removeTag: {
      Tag: "tagID",
    },
    subscribeToCampaign: {
      Sequence: "sequenceName",
    },
    addSubscriber: { Tag: "tagName" },
  },
  ontraport: {
    addTag: {
      Tag: "tagID",
    },
    removeTag: {
      Tag: "tagID",
    },
    subscribeToCampaign: {
      Campaign: "campaignName",
    },
    removeFromCampaign: {
      Campaign: "campaignName",
    },
    addSubscriber: { Tag: "tagName" },
  },
  maropost: {
    addTag: {
      Tag: "tagID",
    },
    removeTag: {
      Tag: "tagID",
    },
    moveList: {
      From: "from-list",
      To: "to-list",
    },
  },
  kartra: {
    addTag: {
      Tag: "tagName",
    },
    removeTag: {
      Tag: "tagName",
    },
    subscribeToSequence: {
      Sequence: "sequenceName",
    },
    removeFromSequence: {
      Sequence: "sequenceName",
    },
  },
  mailerlite: {
    addToGroup: {
      Group: "targetName",
    },
    removeFromGroup: {
      Group: "targetName",
    },
  },
  fbaudience: {
    addToAudience: {
      Audience: "audienceName",
    },
    removeFromAudience: {
      Audience: "audienceName",
    },
    addToFeed: {
      Page: "pageName",
    },
  },
  smartengage: {
    addToSequence: {
      Sequence: "sequenceName",
    },
    removeFromSequence: {
      Sequence: "sequenceName",
    },
    addTag: {
      Tag: "tagName",
    },
    removeTag: {
      Tag: "tagName",
    },
    addSubscriber: { Tag: "tagID" },
  },
  campaignrefinery: {
    addGoal: {
      Goal: "goalName",
    },
    addTag: {
      Tag: "tagName",
    },
  },
  dropcowboy: {
    sendsms: { SMS: "sms-text" },
    sendrvm: { Audio: "filename" },
  },
  constantcontact: {
    addToList: { List: "listName" },
    removeFromList: { List: "listName" },
    addTag: { Tag: "tagID" },
    removeTag: { Tag: "tagID" },
    addSubscriber: { Tag: "registration" },
  },
  zapier: {
    sendWebhook: { Webhook: "webhook" },
  },
  pabbly: {
    sendWebhook: { Webhook: "webhook" },
  },
  wati: {
    sendWhatsAppTemplate: { Template: "template" },
  },
  icontact: {
    moveToList: { List: "listID" },
  },
  klaviyo: {
    addToList: { List: "listName" },
    removeFromList: { List: "listName" },
    sendCampaign: { Campaign: "campaignName" },
  },
  roezan: {
    sendSMS: { SMS: "sms-text" },
    addToList: { List: "listName" },
    removeFromList: { List: "listName" },
  },
  slicktext: {
    sendsms: { SMS: "sms-text" },
    sendmms: { MMS: "mms-text", media: "media-url" },
  },
  manychatwhatsapp: {
    sendWhatsappTemplate: { Automation: "flowName" },
  },
  googlesheets: {
    addToSheet: { Spreadsheet: "spreadsheet", Worksheet: "worksheet" },
  },
  iterable: {
    addToList: { List: "listID" },
    removeFromList: { List: "listID" },
    sendCampaign: { Campaign: "campaignID" },
  },
  gohighlevel: {
    addTag: { Tag: "tagName" },
    addToWorkflow: { Workflow: "workflowID" },
    deleteSubscriber: {},
    removeFromCampaign: { Campaign: "campaignID" },
    removeTag: { Tag: "tagName" },
  },
  campaignmonitor: {
    removeSubscriber: { List: "listID", Client: "clientID" },
  },
  hubspot: {
    addToList: { List: "listID" },
    addToWorkflow: { Workflow: "workflowID" },
    removeFromList: { List: "listID" },
    removeFromWorkflow: { Workflow: "workflowID" },
  },
};
const OBVIO_ACTIONS = {
  // In the NodeEditPanel handle showing helpers.
  "obvio-livechat": {
    icon: "message-processing",
    title: "Auto Chat to Audience",
    type: "obvio-livechat",
  },
  "obvio-cta": {
    icon: "gesture-tap-button",
    title: "CTA Popup",
    type: "obvio-cta",
  },
  "obvio-changesubject": {
    icon: "refresh",
    title: "Change Subject / Title",
    type: "obvio-changesubject",
  },
};

const WEBHOOK_ACTIONS = {
  webhook: {
    title: "Webhook",
    icon: "webhook",
    type: "webhook",
  },
};

const MANYCHAT_WHATSAPP_ACTIONS = {
  "manychatwhatsapp-sendWhatsappTemplate": {
    icon: "whatsapp",
    title: "Send Whatsapp Automation",
  },
};

const GOOGLECALENDAR_ACTIONS = {
  "google-calendar": {
    icon: "link-h",
    title: "Direct Google Calendar Invite",
  },
};

const CALENDAR_ACTIONS = {};

const VSL_ACTIONS = {};

const NIFTYIMAGE_ACTIONS = {};

const AWEBER_ACTIONS = {
  "aweber-addTag": {
    // icon : 'label',
    icon: "tag-plus",
    title: "Add Tag",
  },
  "aweber-moveList": {
    // icon : 'import_export',
    icon: "folder-move",
    title: "Subscriber Move List",
  },
  "aweber-delete": {
    // icon : 'delete',
    icon: "account-remove",
    title: "Delete Subscriber",
  },
};

const MANYCHAT_ACTIONS = {
  "manychat-addTag": {
    icon: "tag-plus",
    title: "Add Tag",
  },
  "manychat-removeTag": {
    icon: "tag-remove",
    title: "Remove Tag",
  },
  "manychat-sendText": {
    icon: "facebook-messenger",
    title: "Send Messenger Msg",
  },
  "manychat-sendMediaMsg": {
    icon: "facebook-messenger",
    title: "Send Messenger Media Msg",
  },
};

const SENDLANE_ACTIONS = {
  "sendlane-addTag": {
    icon: "tag-plus",
    title: "Add Tag",
  },
  "sendlane-removeTag": {
    icon: "tag-remove",
    title: "Remove Tag",
  },
  "sendlane-moveList": {
    icon: "folder-move",
    title: "Subscriber Move List",
  },
  "sendlane-delete": {
    icon: "account-remove",
    title: "Delete Subscriber",
  },
};

const ONTRAPORT_ACTIONS = {
  "ontraport-addTag": {
    icon: "tag-plus",
    title: "Add Tag",
  },
  "ontraport-removeTag": {
    icon: "tag-remove",
    title: "Remove Tag",
  },
  "ontraport-subscribeToCampaign": {
    icon: "folder-plus",
    title: "Subscribe To Automations",
  },
  "ontraport-removeFromCampaign": {
    icon: "folder-remove",
    title: "Remove From Automations",
  },
  "ontraport-delete": {
    icon: "account-remove",
    title: "Delete Subscriber",
  },
};

const MAROPOST_ACTIONS = {
  "maropost-addTag": {
    icon: "tag-plus",
    title: "Add Tag",
  },
  "maropost-removeTag": {
    icon: "tag-remove",
    title: "Remove Tag",
  },
  "maropost-moveList": {
    // icon : 'import_export',
    icon: "folder-move",
    title: "Subscriber Move List",
  },
  "maropost-delete": {
    icon: "account-remove",
    title: "Delete Subscriber",
  },
};

const CONVERTKIT_ACTIONS = {
  "convertkit-addTag": {
    icon: "tag-plus",
    title: "Add Tag",
  },
  "convertkit-removeTag": {
    icon: "tag-remove",
    title: "Remove Tag",
  },
  "convertkit-subscribeToCampaign": {
    icon: "folder-plus",
    title: "Add to Sequence",
  },
  "convertkit-delete": {
    icon: "account-remove",
    title: "Unsubscribe",
  },
};

const KARTRA_ACTIONS = {
  "kartra-addTag": {
    icon: "tag-plus",
    title: "Add Tag",
  },
  "kartra-removeTag": {
    icon: "tag-remove",
    title: "Remove Tag",
  },
  "kartra-subscribeToSequence": {
    icon: "folder-plus",
    title: "Add to Sequence",
  },
  "kartra-removeFromSequence": {
    icon: "account-remove",
    title: "Remove from Sequence",
  },
  "kartra-delete": {
    icon: "account-remove",
    title: "Remove from List",
  },
};

const SMTP_ACTIONS = {
  "smtp-sendMail": {
    icon: "mail",
    title: "Send Email",
  },
};

const SPARKPOST_ACTIONS = {
  "sparkpost-sendMail": {
    icon: "mail",
    title: "Send Email",
  },
};

const AUTOMA8_ACTIONS = {
  "automa8-deleteSystemWide": {
    icon: "refresh",
    title: "Delete System Wide",
    type: "webinar-deleteSystemWide",
  },
  "automa8-addEvent": {
    icon: "calendar-plus",
    title: "Add Another Event",
    type: "webinar-addEvent",
  },
  "webinar-encore": {
    icon: "export",
    title: "Encore Timeline",
    type: "webinar-encore",
  },
};

const MAILCHIMP_ACTIONS = {
  "mailchimp-addTag": {
    icon: "tag-plus",
    title: "Add Tag",
  },
  "mailchimp-moveList": {
    icon: "folder-move",
    title: "Subscriber Move List",
  },
  "mailchimp-delete": {
    icon: "account-remove",
    title: "Delete Subscriber",
  },
  "mailchimp-addToWorkflow": {
    icon: "folder-plus",
    title: "Add To Campaign",
  },
  "mailchimp-removeFromWorkflow": {
    icon: "folder-remove",
    title: "Remove From Campaign",
  },
};

const GETRESPONSE_ACTIONS = {
  "getresponse-addTag": {
    icon: "tag-plus",
    title: "Add Tag",
  },
  "getresponse-moveList": {
    icon: "folder-move",
    title: "Subscriber Move Campaign",
  },
  "getresponse-delete": {
    icon: "account-remove",
    title: "Delete Subscriber",
  },
};

const INFUSIONSOFT_ACTIONS = {
  "infusionsoft-addTag": {
    icon: "tag-plus",
    title: "Add Tag",
  },
  "infusionsoft-removeTag": {
    icon: "tag-remove",
    title: "Remove Tag",
  },
  "infusionsoft-subscribeToCampaign": {
    icon: "folder-plus",
    title: "Subscribe To Campaign",
  },
  "infusionsoft-removeFromCampaign": {
    icon: "folder-remove",
    title: "Remove From Campaign",
  },
  "infusionsoft-delete": {
    icon: "account-remove",
    title: "Delete Subscriber",
  },
};

const DRIP_ACTIONS = {
  "drip-addTag": {
    icon: "tag-plus",
    title: "Add Tag",
  },
  "drip-removeTag": {
    icon: "tag-remove",
    title: "Remove Tag",
  },
  "drip-addToCampaign": {
    icon: "folder-plus",
    title: "Add To Campaign",
  },
  "drip-pauseCampaign": {
    icon: "folder-clock",
    title: "Pause Campaign",
  },
  "drip-removeFromCampaign": {
    icon: "folder-remove",
    title: "Remove From Campaign",
  },
  "drip-startWorkflow": {
    icon: "folder-plus",
    title: "Start Workflow",
  },
  "drip-removeFromWorkflow": {
    icon: "folder-remove",
    title: "Remove From Workflow",
  },
  "drip-deleteSubscriber": {
    icon: "account-remove",
    title: "Delete Subscriber",
  },
};

const ONESIGNAL_ACTIONS = {
  "onesignal-notification": {
    icon: "bell-ring",
    title: "Send Push Notification",
  },
};
const BUSINESSGLU_ACTIONS = {
  "businessglu-sendNotification": {
    icon: "bell-ring",
    title: "Send Notification",
  },
};
const SALESFORCE_ACTIONS = {
  "salesforce-addTag": {
    icon: "tag-plus",
    title: "Add Tag",
  },
  "salesforce-removeTag": {
    icon: "tag-remove",
    title: "Remove Tag",
  },

  "salesforce-addToCampaign": {
    icon: "folder-plus",
    title: "Add to Campaign",
  },
  "salesforce-removeFromCampaign": {
    icon: "folder-remove",
    title: "Remove from Campaign",
  },
  "salesforce-removeLead": {
    icon: "account-remove",
    title: "Remove Lead",
  },
};
const SUPERCHAT_ACTIONS = {
  "superchat-addContactToList": {
    icon: "whatsapp",
    title: "Add Contact To List",
  },
  "superchat-removeContactFromList": {
    icon: "whatsapp",
    title: "Remove Contact From List"
  },
  "superchat-removeContact": {
    icon: "whatsapp",
    title: "Remove Contact",
  },
  "superchat-sendEmail": {
    icon: "whatsapp",
    title: "Send Message",
  }
};
const METACAPI_ACTIONS = {
  "metacapi-sendEvent": {
    icon: "folder-plus",
    title: "Send Event",
  }
};
const TIKTOKBUSINESSAPI_ACTIONS = {
  "tiktokbusinessapi-sendEvent": {
    icon: "folder-plus",
    title: "Send Event",
  }
};
const SIMPLETEXTING_ACTIONS = {
  "simpletexting-sendSms": {
    icon: "message-processing",
    title: "Send SMS",
  },
  "simpletexting-sendMms": {
    icon: "message-image",
    title: "Send MMS",
  },
  "simpletexting-addToList": {
    icon: "folder-plus",
    title: "Add to List",
  },
  "simpletexting-removeFromList": {
    icon: "folder-remove",
    title: "Remove from List",
  },
  "simpletexting-removeContact": {
    icon: "account-remove",
    title: "Remove Contact",
  }
};


const PUSHCREW_ACTIONS = {
  "pushcrew-notification": {
    icon: "bell-ring",
    title: "Send Push Notification",
  },
  "pushcrew-addToSegment": {
    icon: "folder-plus",
    title: "Add To Segment",
  },
  "pushcrew-removeFromSegment": {
    icon: "folder-remove",
    title: "Remove From Segment",
  },
};

const ACTIVECAMPAIGN_ACTIONS = {
  "activecampaign-addTag": {
    icon: "tag-plus",
    title: "Add Tag",
  },
  "activecampaign-removeTag": {
    icon: "tag-remove",
    title: "Remove Tag",
  },
  "activecampaign-addToAutomation": {
    icon: "folder-plus",
    title: "Add To Automation",
  },
  "activecampaign-removeFromAutomation": {
    icon: "folder-remove",
    title: "Remove From Automation",
  },
  "activecampaign-moveList": {
    // icon : 'import_export',
    icon: "folder-move",
    title: "Subscriber Move List",
  },
  "activecampaign-deleteSubscriber": {
    icon: "account-remove",
    title: "Delete Subscriber",
  },
};

const PUSHOVER_ACTIONS = {
  "pushover-notification": {
    icon: "bell-ring",
    title: "Send Push Notification",
  },
};

const TWILIO_ACTIONS = {
  "twilio-textuser": {
    title: "Send SMS",
    icon: "message-processing",
  },
  "twilio-sendmms": {
    title: "Send MMS",
    icon: "message-image",
  },
};

const SLYBROADCAST_ACTIONS = {
  "slybroadcast-vmmsg": {
    icon: "voicemail",
    title: "Deliver VM Msg",
  },
};

const WEBINAR_ACTIONS = {
  // In the NodeEditPanel handle showing helpers.
  "webinar-livechat": {
    icon: "message-processing",
    title: "Auto Chat to Audience",
    type: "webinar-livechat",
  },
  "webinar-cta": {
    icon: "gesture-tap-button",
    title: "CTA Popup",
    type: "webinar-cta",
  },
  "webinar-changesubject": {
    icon: "refresh",
    title: "Change Subject / Title",
    type: "webinar-changesubject",
  },
};

const MAILERLITE_ACTIONS = {
  "mailerlite-addToGroup": {
    icon: "folder-plus",
    title: "Add To Group",
    type: "mailerlite-addToGroup",
  },
  "mailerlite-removeFromGroup": {
    icon: "folder-remove",
    title: "Remove From Group",
    type: "mailerlite-removeFromGroup",
  },
};

const FBAUDIENCE_ACTIONS = {
  "fbaudience-addToAudience": {
    icon: "folder-plus",
    title: "Add To Audience",
    type: "fbaudience-addToAudience",
  },
  "fbaudience-removeFromAudience": {
    icon: "folder-remove",
    title: "Remove From Audience",
    type: "fbaudience-removeFromAudience",
  },
  "fbaudience-addToFeed": {
    title: "Post To Feed",
    icon: "message-processing",
  },
};

const SMARTENGAGE_ACTIONS = {
  "smartengage-addToSequence": {
    icon: "folder-plus",
    title: "Add To Sequence",
  },
  "smartengage-removeFromSequence": {
    icon: "folder-remove",
    title: "Remove From Sequence",
  },
  "smartengage-addTag": {
    icon: "tag-plus",
    title: "Add Tag",
  },
  "smartengage-removeTag": {
    icon: "tag-remove",
    title: "Remove Tag",
  },
};

const AMAZONSES_ACTIONS = {
  "amazonses-sendMail": {
    icon: "mail",
    title: "Send Email",
  },
};
const GAMAIL_ACTIONS = {
  "gmail-sendMail": {
    icon: "mail",
    title: "Send Email",
  },
};

const SENDGRID_ACTIONS = {
  "sendgrid-sendMail": {
    icon: "mail",
    title: "Send Email",
  },
};

const MAILGUN_ACTIONS = {
  "mailgun-sendMail": {
    icon: "mail",
    title: "Send Email",
  },
};

const MAILJET_ACTIONS = {
  "mailjet-sendMail": {
    icon: "mail",
    title: "Send Email",
  },
};

const POSTMARK_ACTIONS = {
  "postmark-sendMail": {
    icon: "mail",
    title: "Send Email",
  },
};

const SENDINBLUE_ACTIONS = {
  "sendinblue-sendMail": {
    icon: "mail",
    title: "Send Email",
  },
};

const CAMPAIGNREFINERY_ACTIONS = {
  "campaignrefinery-addGoal": {
    icon: "folder-plus",
    title: "Add Goal",
  },
  "campaignrefinery-addTag": {
    icon: "tag-plus",
    title: "Add Tag",
  },
};

const SLACK_ACTIONS = {
  "slack-supportChat": {
    icon: "mail",
    title: "Send Chat to Slack",
  },
};

const DOMAIN_ACTIONS = {
  "domain-actions": {
    icon: "link-h",
    title:
      "Join Link / Replay Link / AEvent.Stream all under your own CName Sub-Domain",
  },
};
// Hubspot actions
const HUBSPOT_ACTIONS = {
  // "hubspot-addTag": {
  //     icon: "tag-plus",
  //     title: "Add Tag",
  //     type: "hubspot-addTag",
  // },
  "hubspot-addToWorkflow": {
    icon: "folder-plus",
    title: "Add Contact To Workflow",
    type: "hubspot-addToWorkflow",
  },
  "hubspot-removeFromWorkflow": {
    icon: "folder-remove",
    title: "Remove Contact From Workflow",
    type: "hubspot-removeFromWorkflow",
  },
  "hubspot-addToList": {
    icon: "folder-plus",
    title: "Add Contact To List",
    type: "hubspot-addToList",
  },
  "hubspot-removeFromList": {
    icon: "folder-remove",
    title: "Remove Contact From List",
    type: "hubspot-removeFromList",
  },
  "hubspot-removeContact": {
    icon: "account-remove",
    title: "Delete Contact",
    type: "hubspot-removeContact",
  },
};

// Gohighlevel actions
const GOHIGHLEVEL_ACTIONS = {
  "gohighlevel-addTag": {
    icon: "tag-plus",
    title: "Add Tag",
    type: "gohighlevel-addTag",
  },
  "gohighlevel-removeTag": {
    icon: "tag-remove",
    title: "Remove Tag",
    type: "gohighlevel-removeTag",
  },
  "gohighlevel-subscribeToCampaign": {
    icon: "folder-plus",
    title: "Subscribe To Campaign",
    type: "gohighlevel-subscribeToCampaign",
  },
  "gohighlevel-removeFromCampaign": {
    icon: "folder-remove",
    title: "Remove From Campaign",
    type: "gohighlevel-removeFromCampaign",
  },
  "gohighlevel-addToWorkflow": {
    icon: "folder-plus",
    title: "Add To Workflow",
  },
  "gohighlevel-deleteSubscriber": {
    icon: "account-remove",
    title: "Delete Subscriber",
  },
};
const DROPCOWBOY_ACTIONS = {
  "dropcowboy-sendsms": {
    title: "Send SMS",
    icon: "message-processing",
  },
  "dropcowboy-sendrvm": {
    icon: "voicemail",
    title: "Send Ringless Voicemail",
  },
};
const CLICKBANK_ACTIONS = {
  "clickbank-sendemail": {
    title: "Vendor Affiliate Email Attribution",
    icon: "mail",
  },
};
const CONSTANTCONTACT_ACTIONS = {
  "constantcontact-addToList": {
    icon: "folder-plus",
    title: "Add Contact To List",
    type: "constantcontact-addToList",
  },
  "constantcontact-removeFromList": {
    icon: "folder-remove",
    title: "Remove Contact From List",
    type: "constantcontact-removeFromList",
  },
  "constantcontact-addTag": {
    icon: "tag-plus",
    title: "Add Tag",
    type: "constantcontact-addTag",
  },
  "constantcontact-removeTag": {
    icon: "tag-remove",
    title: "Remove Tag",
    type: "constantcontact-removeTag",
  },
  "constantcontact-removeContact": {
    icon: "account-remove",
    title: "Delete Contact",
    type: "constantcontact-removeContact",
  },
};

const ZAPIER_ACTIONS = {
  "zapier-sendWebhook": {
    icon: "webhook",
    title: "Send Webhook",
  },
};
const PABBLY_ACTIONS = {
  "pabbly-sendWebhook": {
    icon: "webhook",
    title: "Send Webhook",
  },
};

const WATI_ACTIONS = {
  "wati-sendWhatsAppTemplate": {
    icon: "whatsapp",
    title: "Send WhatsApp Template",
  },
};

const ICONTACT_ACTIONS = {
  "icontact-moveToList": {
    icon: "folder-plus",
    title: "Add Contact To List",
  },
};

const KLAVIYO_ACTIONS = {
  "klaviyo-addToList": {
    icon: "folder-plus",
    title: "Add Contact To List",
  },
  "klaviyo-removeFromList": {
    icon: "folder-remove",
    title: "Remove Contact From List",
  },
  "klaviyo-sendCampaign": {
    icon: "mail",
    title: "Send Campaign",
  },
};

const ROEZAN_ACTIONS = {
  "roezan-sendSMS": {
    title: "Send SMS",
    icon: "message-processing",
  },
  "roezan-addToList": {
    icon: "folder-plus",
    title: "Add Contact To List",
  },
  "roezan-addTag": {
    icon: "folder-plus",
    title: "Add Tag",
  },
  "roezan-removeTag": {
    icon: "tag-remove",
    title: "Remove Tag",
  },
  "roezan-removeFromList": {
    icon: "folder-remove",
    title: "Remove Contact From List",
  },
};

const SLICKTEXT_ACTIONS = {
  "slicktext-sendsms": {
    title: "Send SMS",
    icon: "message-processing",
  },
  "slicktext-sendmms": {
    title: "Send MMS",
    icon: "message-image",
  },
  "slicktext-removeContact": {
    title: "Remove Contact",
    icon: "folder-remove",
  },
};

const SALESMESSAGE_ACTIONS = {
  "salesmessage-addTag": {
    icon: "tag-plus",
    title: "Add Tag",
  },
  "salesmessage-removeTag": {
    icon: "tag-remove",
    title: "Remove Tag",
  },
  "salesmessage-sendsms": {
    icon: "message-processing",
    title: "Send SMS",
  },
  "salesmessage-sendmms": {
    icon: "message-image",
    title: "Send MMS",
  },
  "salesmessage-removeContact": {
    icon: "account-remove",
    title: "Remove Contact",
  },
};

const CAMPAIGNMONITOR_ACTIONS = {
  "campaignmonitor-removeSubscriber": {
    title: "Remove Subscriber",
    icon: "account-remove",
  },
};

const GOOGLESHEETS_ACTIONS = {
  "googlesheets-addToSheet": {
    icon: "folder-plus",
    title: "Add to sheet",
  },
};

const ITERABLE_ACTIONS = {
  "iterable-sendCampaign": {
    icon: "mail",
    title: "Send Campaign",
  },
  "iterable-addToList": {
    icon: "folder-plus",
    title: "Add Contact To List",
  },
  "iterable-removeFromList": {
    icon: "folder-remove",
    title: "Remove Contact From List",
  },
};

const BOOKING_ACTIONS = {};

// ALL ACTIONS LIST
export const ACTION_LIST = {
  ACTIVECAMPAIGN: ACTIVECAMPAIGN_ACTIONS,
  AUTOMA8: AUTOMA8_ACTIONS,
  AWEBER: AWEBER_ACTIONS,
  AMAZONSES: AMAZONSES_ACTIONS,
  CONVERTKIT: CONVERTKIT_ACTIONS,
  CAMPAIGNREFINERY: CAMPAIGNREFINERY_ACTIONS,
  CAMPAIGNMONITOR: CAMPAIGNMONITOR_ACTIONS,
  CLICKBANK: CLICKBANK_ACTIONS,
  CONSTANTCONTACT: CONSTANTCONTACT_ACTIONS,
  CALENDAR: CALENDAR_ACTIONS,
  DOMAIN: DOMAIN_ACTIONS,
  DROPCOWBOY: DROPCOWBOY_ACTIONS,
  DRIP: DRIP_ACTIONS,
  WEBHOOK: WEBHOOK_ACTIONS,
  FBAUDIENCE: FBAUDIENCE_ACTIONS,
  GOHIGHLEVEL: GOHIGHLEVEL_ACTIONS,
  GOOGLECALENDAR: GOOGLECALENDAR_ACTIONS,
  GOOGLESHEETS: GOOGLESHEETS_ACTIONS,
  GETRESPONSE: GETRESPONSE_ACTIONS,
  INFUSIONSOFT: INFUSIONSOFT_ACTIONS,
  ICONTACT: ICONTACT_ACTIONS,
  ITERABLE: ITERABLE_ACTIONS,
  HUBSPOT: HUBSPOT_ACTIONS,
  KLAVIYO: KLAVIYO_ACTIONS,
  KARTRA: KARTRA_ACTIONS,
  MAILCHIMP: MAILCHIMP_ACTIONS,
  MAROPOST: MAROPOST_ACTIONS,
  MAILERLITE: MAILERLITE_ACTIONS,
  MAILGUN: MAILGUN_ACTIONS,
  MAILJET: MAILJET_ACTIONS,
  MANYCHAT: MANYCHAT_ACTIONS,
  NIFTYIMAGE: NIFTYIMAGE_ACTIONS,
  ONTRAPORT: ONTRAPORT_ACTIONS,
  ONESIGNAL: ONESIGNAL_ACTIONS,
  OBVIO: OBVIO_ACTIONS,
  PUSHCREW: PUSHCREW_ACTIONS,
  POSTMARK: POSTMARK_ACTIONS,
  //"PUSHOVER": PUSHOVER_ACTIONS,
  ROEZAN: ROEZAN_ACTIONS,
  SENDLANE: SENDLANE_ACTIONS,
  SMTP: SMTP_ACTIONS,
  SENDGRID: SENDGRID_ACTIONS,
  SLYBROADCAST: SLYBROADCAST_ACTIONS,
  SPARKPOST: SPARKPOST_ACTIONS,
  SENDINBLUE: SENDINBLUE_ACTIONS,
  SMARTENGAGE: SMARTENGAGE_ACTIONS,
  MANYCHATWHATSAPP: MANYCHAT_WHATSAPP_ACTIONS,
  SALESMESSAGE: SALESMESSAGE_ACTIONS,
  SLACK: SLACK_ACTIONS,
  SLICKTEXT: SLICKTEXT_ACTIONS,
  TWILIO: TWILIO_ACTIONS,
  VSL: VSL_ACTIONS,
  WATI: WATI_ACTIONS,
  WEBINAR: WEBINAR_ACTIONS,
  YOUTUBE: WEBINAR_ACTIONS,
  ZOOM: WEBINAR_ACTIONS,
  GOTOWEBINAR: WEBINAR_ACTIONS,
  OBVIO: WEBINAR_ACTIONS,
  ZAPIER: ZAPIER_ACTIONS,
  PABBLY: PABBLY_ACTIONS,
  AEVENT: WEBINAR_ACTIONS,
  ZOOMAUTOCHAT: {},
  BUSINESSGLU: BUSINESSGLU_ACTIONS,
  GMAIL: GAMAIL_ACTIONS,
  CALENDLY: BOOKING_ACTIONS,
  SALESFORCE: SALESFORCE_ACTIONS,
  METACAPI:METACAPI_ACTIONS,
  SIMPLETEXTING:SIMPLETEXTING_ACTIONS,
  SUPERCHAT: SUPERCHAT_ACTIONS,
  TIKTOKBUSINESSAPI:TIKTOKBUSINESSAPI_ACTIONS,
};

// INTEGRATION TYPES WITHOUT OAUTH
export const DIRECT_INTEGRATIONS = [
  "activecampaign",
  "amazonses",
  "aevent",
  "clickbank",
  "campaignrefinery",
  "convertkit",
  "domain",
  "dropcowboy",
  "gohighlevel",
  "icontact",
  "iterable",
  "kartra",
  "klaviyo",
  "mailgun",
  "mailjet",
  "mailerlite",
  "manychat",
  "manychatwhatsapp",
  "maropost",
  "niftyimage",
  "obvio",
  "onesignal",
  "ontraport",
  "postmark",
  "pushcrew",
  "roezan",
  "sendlane",
  "sendgrid",
  "sendinblue",
  "slicktext",
  "smtp",
  "smartengage",
  "sparkpost",
  "slybroadcast",
  "twilio",
  "wati",
  "zapier",
  "businessglu",
  "salesforce",
  "pabbly",
  "metacapi",
  "simpletexting",
  "superchat",
  "tiktokbusinessapi",
];

export const INTEGRATION_FIELDS = {
  activecampaign: [
    {
      label: "API URL",
      model: "api_url",
      type: "text",
    },
    {
      label: "API Key",
      model: "api_key",
      type: "text",
    },
  ],
  amazonses: [
    {
      label: "AWS Access Key ID",
      model: "aws_access_key_id",
      type: "text",
    },
    {
      label: "AWS Secret Access Key",
      model: "aws_secret_access_key",
      type: "text",
    },
    {
      label: "Region",
      model: "aws_region",
      type: "text",
    },
  ],
  campaignrefinery: [
    {
      label: "API Key",
      model: "api_key",
      type: "text",
    },
  ],
  clickbank: [
    {
      label: "Vendor ID",
      model: "vendor_id",
      type: "text",
    },
  ],
  convertkit: [
    {
      label: "API Key",
      model: "api_key",
      type: "text",
    },
    {
      label: "API Secret",
      model: "api_secret",
      type: "text",
    },
  ],
  domain: [
    {
      label: "Domain",
      model: "domain",
      type: "text",
      validate: (domain) => {
        let re = new RegExp(
          /^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/
        );
        if (domain.match(re)) return true;
        else return false;
      },
    },
    {
      label: "Default Redirect",
      model: "defaultRedirect",
      type: "text",
      validate: (domain) => {
        if (!domain) return true;
        let re = new RegExp(
          /^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/
        );
        if (domain.match(re)) return true;
        else return false;
      },
    },
  ],
  dropcowboy: [
    {
      label: "Team ID",
      model: "team_id",
      type: "text",
    },
    {
      label: "Secret",
      model: "secret",
      type: "text",
    },
  ],
  gohighlevel: [
    {
      label: "API Key",
      model: "api_key",
      type: "text",
    },
  ],
  icontact: [
    {
      label: "API App ID",
      model: "api_app_id",
      type: "text",
    },
    {
      label: "API Username",
      model: "api_username",
      type: "text",
    },
    {
      label: "API Password",
      model: "api_password",
      type: "text",
    },
    {
      label: "Account ID",
      model: "account_id",
      type: "text",
    },
  ],
  iterable: [
    {
      label: "API Key",
      model: "api_key",
      type: "text",
    },
  ],
  kartra: [
    {
      label: "API Key",
      model: "api_key",
      type: "text",
    },
    {
      label: "API PASSWORD",
      model: "api_pass",
      type: "text",
    },
  ],
  klaviyo: [
    {
      label: "Private API Key",
      model: "private_api_key",
      type: "text",
    },
  ],
  mailerlite: [
    {
      label: "API Key",
      model: "api_key",
      type: "text",
    },
  ],
  mailgun: [
    {
      label: "API Key",
      model: "api_key",
      type: "text",
    },
    {
      label: "API Domain",
      model: "api_domain",
      type: "text",
    },
    {
      label: "Account Type",
      model: "accountType",
      type: "select",
      data: {
        us: "US",
        eu: "EU",
      },
    },
  ],
  mailjet: [
    {
      label: "API Key",
      model: "api_key",
      type: "text",
    },
    {
      label: "API Secret",
      model: "api_secret",
      type: "text",
    },
  ],
  maropost: [
    {
      label: "Account ID",
      model: "account_id",
      type: "text",
    },
    {
      label: "API Key",
      model: "api_key",
      type: "text",
    },
  ],
  manychat: [
    {
      label: "API Key",
      model: "api_key",
      type: "text",
    },
    {
      label: "Widget ID",
      model: "widgetID",
      type: "text",
    },
    {
      label: "Reference ID",
      model: "referenceID",
      type: "text",
    },
  ],
  manychatwhatsapp: [
    {
      label: "API Key",
      model: "api_key",
      type: "text",
    },
  ],
  niftyimage: [
    {
      label: "API Key",
      model: "api_key",
      type: "text",
    },
  ],
  obvio: [
    {
      label: "API Key",
      model: "api_key",
      type: "text",
    },
  ],
  ontraport: [
    {
      label: "APP ID",
      model: "app_id",
      type: "text",
    },
    {
      label: "API Key",
      model: "api_key",
      type: "text",
    },
  ],
  onesignal: [
    {
      label: "App ID",
      model: "app_id",
      type: "text",
    },
    {
      label: "OneSignal App REST API Key",
      model: "rest_api_key",
      type: "text",
    },
  ],
  postmark: [
    {
      label: "API Key",
      model: "api_key",
      type: "text",
    },
  ],
  pushcrew: [
    {
      label: "Account ID",
      model: "account_id",
      type: "text",
    },
    {
      label: "API Token",
      model: "api_key",
      type: "text",
    },
  ],
  roezan: [
    {
      label: "Username",
      model: "username",
      type: "text",
    },
    {
      label: "Password",
      model: "password",
      type: "password",
    },
  ],
  sendlane: [
    {
      label: "Subdomain",
      model: "subdomain",
      type: "text",
    },
    {
      label: "API Key",
      model: "api_key",
      type: "text",
    },
    {
      label: "Hash Key",
      model: "hash_key",
      type: "text",
    },
  ],
  sendgrid: [
    {
      label: "API Key",
      model: "api_key",
      type: "text",
    },
  ],
  sendinblue: [
    {
      label: "API Key",
      model: "api_key",
      type: "text",
    },
  ],
  smartengage: [
    {
      label: "API Key",
      model: "api_key",
      type: "text",
    },
  ],
  slicktext: [
    {
      label: "Public API Key",
      model: "public_api_key",
      type: "text",
    },
    {
      label: "Private API Key",
      model: "private_api_key",
      type: "text",
    },
  ],
  slybroadcast: [
    {
      label: "Account Type",
      model: "accountType",
      type: "select",
      data: {
        intl: "Global",
        us: "US",
      },
    },
    {
      label: "Username",
      model: "username",
      type: "text",
    },
    {
      label: "Password",
      model: "password",
      type: "password",
    },
  ],
  smtp: [
    {
      label: "Host",
      model: "address",
      type: "text",
    },
    {
      label: "Port",
      model: "port",
      type: "text",
    },
    {
      label: "Username",
      model: "username",
      type: "text",
    },
    {
      label: "Password",
      model: "password",
      type: "password",
    },
  ],
  sparkpost: [
    {
      label: "API Key",
      model: "api_key",
      type: "text",
    },
  ],
  twilio: [
    {
      label: "Account SID",
      model: "sid",
      type: "text",
    },
    {
      label: "Auth Token",
      model: "token",
      type: "text",
    },
  ],
  wati: [
    {
      label: "Access Token",
      model: "access_token",
      type: "text",
    },
    {
      label: "API Endpoint",
      model: "api_url",
      type: "text",
    },
  ],
  zapier: [
    {
      label: "Key",
      model: "key",
      type: "text",
    },
  ],
  pabbly: [
    {
      label: "Key",
      model: "key",
      type: "text",
    },
  ],
  businessglu: [
    {
      label: "Team ID",
      model: "team_id",
      type: "text",
    },
  ],
  salesforce: [
    {
      label: "My Domain Url",
      model: "domain",
      type: "text",
    },
    {
      label: "Client Secret",
      model: "client_secret",
      type: "text",
    },
    {
      label: "Client Id",
      model: "client_id",
      type: "text",
    },
  ], 
  metacapi: [
    {
      label: "Pixel ID",
      model: "pixel_id",
      type: "text",
    },
    {
      label: "Client Secret",
      model: "client_secret",
      type: "text",
    }
  ],
  simpletexting: [
    {
      label: "API Key",
      model: "api_key",
      type: "text",
    }
  ],
  superchat: [
    {
      label: "API Key",
      model: "api_key",
      type: "text",
    },
  ],
  tiktokbusinessapi: [
    {
      label: "Pixel ID",
      model: "offline_event_set_id",
      type: "text",
    },
    {
      label: "Access Token",
      model: "access_token",
      type: "text",
    }
  ]
};
// ALL AUTORESPONDERS
export const AUTORESPONDER_TYPE = [
  "ACTIVECAMPAIGN",
  "AEVENT",
  "AMAZONSES",
  "AWEBER",
  "CAMPAIGNMONITOR",
  "CAMPAIGNREFINERY",
  "CALENDAR",
  "CLICKBANK",
  "CONSTANTCONTACT",
  "CONVERTKIT",
  "DRIP",
  "DROPCOWBOY",
  "FBAUDIENCE",
  "GETRESPONSE",
  "GOHIGHLEVEL",
  "GOOGLESHEETS",
  "GOOGLECALENDAR",
  "HUBSPOT",
  "ICONTACT",
  "INFUSIONSOFT",
  "ITERABLE",
  "KARTRA",
  "KLAVIYO",
  "MAILCHIMP",
  "MAILERLITE",
  "MAILGUN",
  "MAILJET",
  "MAILCHIMP",
  "MAILERLITE",
  "MAILGUN",
  "MAILJET",
  "MAROPOST",
  "MANYCHAT",
  "MANYCHATWHATSAPP",
  "ONTRAPORT",
  "POSTMARK",
  "ROEZAN",
  "SALESMESSAGE",
  "SENDGRID",
  "SENDINBLUE",
  "SENDLANE",
  "SMARTENGAGE",
  "SLACK",
  "SLICKTEXT",
  "SLYBROADCAST",
  "SMTP",
  "SPARKPOST",
  "TWILIO",
  "WATI",
  "WEBHOOK",
  "ZAPIER",
  "PABBLY",
  "ZOOM",
  "GOTOWEBINAR",
  "OBVIO",
  "YOUTUBE",
  "VSL",
  "ONESIGNAL",
  "BUSINESSGLU",
  "GMAIL",
  "CALENDLY",
  "SALESFORCE",
  "METACAPI",
  "SIMPLETEXTING",
  "SUPERCHAT",
  "TIKTOKBUSINESSAPI",
];

export const PLATFORM_TYPE = [
  "AEVENT",
  "ZOOM",
  "GOTOWEBINAR",
  "OBVIO",
  "YOUTUBE",
  "CALENDLY",
];

export const OAUTH_CALLBACK_PARAMS = {
  youtubecallback: {
    name: "youtube",
    params: ["code"],
  },
  gotocallback: {
    name: "gotowebinar",
    params: ["code"],
  },
  zoomcallback: {
    name: "zoom",
    params: ["code"],
  },
  zoomautochatcallback: {
    name: "zoomautochat",
    params: ["code"],
  },
  twiliocallback: {
    name: "twilio",
    params: ["AccountSid"],
  },
  awebercallback: {
    name: "aweber",
    params: ["code"],
  },
  getresponsecallback: {
    name: "getresponse",
    params: ["code"],
  },
  mailchimpcallback: {
    name: "mailchimp",
    params: ["code"],
  },
  infusionsoftcallback: {
    name: "infusionsoft",
    params: ["code", "scope", "state"],
  },
  dripcallback: {
    name: "drip",
    params: ["code"],
  },
  fbcallback: {
    name: "fbaudience",
    params: ["code", "state"],
  },
  hubspotcallback: {
    name: "hubspot",
    params: ["code"],
  },
  constantcontactcallback: {
    name: "constantcontact",
    params: ["code"],
  },
  slackcallback: {
    name: "slack",
    params: ["code"],
  },
  googlecalendarcallback: {
    name: "googlecalendar",
    params: ["code"],
  },
  salesmessagecallback: {
    name: "salesmessage",
    params: ["code"],
  },
  campaignmonitorcallback: {
    name: "campaignmonitor",
    params: ["code"],
  },
  googlesheetscallback: {
    name: "googlesheets",
    params: ["code"],
  },
  gmailcallback: {
    name: "gmail",
    params: ["code"],
  },
  calendlycallback: {
    name: "calendly",
    params: ["code"],
  },
};
export const TRACKING_PIXEL_INTEGRATIONS = [
  "SMTP",
  "SPARKPOST",
  "AMAZONSES",
  "SENDGRID",
  "MAILGUN",
  "MAILJET",
  "POSTMARK",
  "SENDINBLUE",
  "GMAIL",
];
