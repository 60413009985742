import { defineStore } from "pinia";

export const useMediaStore = defineStore({
  id: "media-store",
  state: () => {
    return {
      images: [],
      audios: [],
      videos: [],
      folders: [],
      files: [],
      uploadMediaToFolder: "",
      previewData:{},
      isPreview: false
    };
  },
  actions: {
    async fetchImages() {
      const response = await call("/media/ajaxGetFiles/image", "GET");
      if (response) {
        const { success } = response;

        this.images = success;
      }
      return response;
    },
    async fetchAudios() {
      const response = await call("/media/ajaxGetFiles/audio", "GET");
      if (response) {
        const { success } = response;
        this.audios = success;
      }
      return response;
    },
    async fetchVideos() {
      const response = await call("/media/ajaxGetFiles/video", "GET");
      if (response) {
        const { success } = response;
        this.videos = success;
      }
      return response;
    },
    async renameMedia(mediaToRename) {
      const response = await call("/media/renameFile", "POST", mediaToRename);
      if (response) {
        const { success } = response;
        if (mediaToRename.data.type === "image") {
          this.fetchImages();
        } else if (mediaToRename.data.type === "audio") {
          this.fetchAudios();
        } else if (mediaToRename.data.type === "video") {
          this.fetchVideos();
        }
      }
      return response;
    },
    async deleteMedia(uniqueID, mediaType) {
      const response = await call(`/media/${uniqueID}`, "DELETE", null, true);
      const message = response?.error?.response?.data?.message ?? "";
      if (message) {
        return { error: message };
      }
      let deleteResponse = false;
      if (response) {
        if (mediaType === "image") {
          deleteResponse = Object.entries(this.images)
            .filter(([key, value]) => value.uniqueID !== uniqueID)
            .map(([key, value]) => value);
          this.images = deleteResponse;
        } else if (mediaType === "audio") {
          deleteResponse = Object.entries(this.audios)
            .filter(([key, value]) => value.uniqueID !== uniqueID)
            .map(([key, value]) => value);

          this.audios = deleteResponse;
        } else if (mediaType === "video") {
          deleteResponse = Object.entries(this.videos)
            .filter(([key, value]) => value.uniqueID !== uniqueID)
            .map(([key, value]) => value);
          this.videos = deleteResponse;
        }
        return deleteResponse;
      } else {
        return false;
      }
    },

    // folders structure part

    async getFolders() {
      let response = await call("/media/ajaxGetFiles/tree", "GET");

      if (response) {
        this.folders = response.success.filter((item) => item.type == "folder");
        this.files = response.success.filter((item) => item.type != "folder");
      }
      return response;
    },

    async createFolder(name, type) {
      let payload = {
        name: name,
        type: type,
      };
      let response = await call("/media/create", "POST", payload);
      if (response) {
        this.getFolders();
      }
    },

    async addToFolders(payload) {
      let response = await call("/media/update", "PUT", payload);
      if (response) {
        this.getFolders();
        return response;
      }
      return response;
    },
    async deleteFolder(id) {
      let response = await call(`/media/${id}`, "DELETE");
      if (response) {
        this.getFolders();
      }
      return response;
    },

    totalMediaSize(type = 0) {
      // send type = 1 when required in GB, default = 0
      let sum = 0;
      sum += getTotalSizeByType(this.videos);
      sum += getTotalSizeByType(this.audios);
      sum += getTotalSizeByType(this.images);
      if (type == 1) {
        return parseFloat(sum / 1024 / 1024 / 1024).toFixed(2);
      }
      return sum ?? 0;
    },
    async setUploadMediaToFolder(value) {
      this.uploadMediaToFolder = value;
    },
    async setPreviewData(value) {
      this.previewData = value;
    },
    async setIsPreview(value) {
      this.isPreview = value;
    }

  },
  getters: {
    imagesList: (state) => state.images,
    audiosList: (state) => state.audios,
    videosList: (state) => state.videos,
    foldersList: (state) => state.folders,
    filesList: (state) => state.files,
    uploadToFolder: (state) => state.uploadMediaToFolder,
    previewData:(state) => state.previewData,
    isPreview:(state) => state.isPreview,
  },
});

const getTotalSizeByType = (medias) => {
  let sum = 0;
  Object.values(medias).map((media) => {
    sum += parseInt(media.size);
  });
  return sum;
};
