<template>
  <div>
    <div v-if="isPreviewData?.isPreview && isPreviewData?.type == 'image'">
      <div
        @click.stop="handlePreviewClose"
        class="fixed top-0 left-0 z-[999999999] w-screen h-screen bg-black/70"
      ></div>
      <div
        class="fixed top-[50%] left-[50%] z-[9999999999] translate-x-[-50%] translate-y-[-50%] w-[350px] md:w-[725px] h-[425px] rounded-xl bg-neutral-100 dark:bg-neutral-800 flex justify-center items-center"
      >
        <img
          :src="isPreviewData?.upload_url"
          :alt="image"
          class="object-cover"
        />
      </div>
    </div>
    <div v-if="isPreviewData?.isPreview && isPreviewData?.type == 'video'">
      <div
        @click.stop="handlePreviewClose"
        class="fixed top-0 left-0 z-[999999999] w-screen h-screen bg-black/70"
      ></div>
      <div class="w-12 h-12">
        <video
          :poster="isPreviewData?.thumbnail"
          controls
          autoplay
          class="fixed top-[50%] left-[50%] z-[9999999999] translate-x-[-50%] translate-y-[-50%] w-[350px] max-w-[350px] md:w-[725px] md:max-w-[725px] h-[425px] rounded-xl object-cover"
          style="transform: translate(-50%, -50%)"
        >
          <source :src="isPreviewData?.upload_url" type="video/mp4" />
        </video>
      </div>
    </div>
    <div v-if="isPreviewData?.isPreview && isPreviewData?.type == 'audio'">
      <div
        @click.stop="handlePreviewClose"
        class="fixed top-0 left-0 z-[999999999] w-screen h-screen bg-black/70"
      ></div>
      <div class="">
        <audio
          controls
          class="bg-white dark:bg-neutral-800 overflow-hidden fixed top-[50%] left-[50%] z-[9999999999] translate-x-[-50%] translate-y-[-50%]"
        >
          <source :src="isPreviewData?.upload_url" type="audio/ogg" />
          Your browser does not support the audio element.
        </audio>
      </div>
    </div>
  </div>
</template>
<script setup>
import { storeToRefs } from "pinia";
import { useMediaStore } from "~~/store/media";
const mediaStore = useMediaStore();
const isPreviewData = ref(mediaStore.previewData);
const handlePreviewClose = (media) => {
  mediaStore.setPreviewData({});
};
</script>
