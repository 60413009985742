<template>
  <div class="font-manrope">
    <ReusableUIBanner />
    <NuxtPage />
    <!-- Global Modals  -->
    <ReusableUIIntercomChat v-if="showIntercom" />
    <ReusableUIDeploymentToast :showToast="isDeploymentCompleted" />
    <MediaCompsUploadMedia v-if="isuploadMediaModal" />
    <ReusableUIMediaPreview v-if="mediaStore.previewData?.isPreview" />
  </div>
</template>
<script setup>
import { useCommonStore } from "/store/common";
import { useAuthStore } from "/store/auth";
import { storeToRefs } from "pinia";
import { useMediaStore } from "~~/store/media";

import "/parse.min.js";
import "/google-api.js";
import "/public/assets/css/show-intercom.css";
const authStore = useAuthStore();
const commonStore = useCommonStore();
const colorMode = useColorMode();
const mediaStore = useMediaStore();


const { isuploadMediaModal } = storeToRefs(commonStore);
const router = useRouter();

const isDeploymentCompleted = ref(false);
const onLogin = () => {
  return router.currentRoute.value.name.match(/login|invoker/);
};
const onPopup = () => {
  return (
    router.currentRoute.value.path === "/integration" &&
    typeof router.currentRoute.value.query.type !== "undefined"
  );
};
const showIntercom = ref(false);
const checkIntercom = () => {
  const shouldShow = !onLogin() && !onPopup();
  if (showIntercom.value !== shouldShow) {
    showIntercom.value = shouldShow;
  }
};

const loadData = async () => {
  if (onLogin()) return;
  const validate = await authStore.validate();
  if (validate?.success) {
    authStore.user = validate?.success;
    colorMode.preference = validate?.success.profile?.theme;
    const response = await getData();
    if (response) {
      commonStore.setAllLoaded(true);
    }
  }
};

onBeforeMount(() => {
  loadData();
  checkIntercom();
});
watch(
  () => router.currentRoute.value.name,
  (val, old) => {
    if (old === "login" && val !== "forgotPassword" && val !== "landing") {
      loadData();
    }
    checkIntercom();
  }
);
</script>
<style>
.font-manrope {
  font-family: "Manrope";
}
</style>
